module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-valine/gatsby-browser.js'),
      options: {"plugins":[],"appId":"U16yUflAALySICZhsJiwmobC-gzGzoHsz","appKey":"SuQzXF7zopGW41PIraZdurG3","avatar":"robohash"},
    },{
      plugin: require('../node_modules/gatsby-remark-autolink-headers/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../plugins/gatsby-remark-code-buttons/gatsby-browser.js'),
      options: {"plugins":[],"buttonContainerClass":"whatsoever-button-container-class","buttonClass":"whatsoever-button-class","buttonText":"Copy"},
    },{
      plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":1200,"showCaptions":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Whatsoever","short_name":"whatsoever","start_url":"/","background_color":"#ffffff","theme_color":"#8a4baf","display":"minimal-ui","icon":"src/images/favicon.jpeg","icons":[{"src":"src/images/maskable_icon_x192.png","sizes":"192x192","type":"image/png","purpose":"any maskable"},{"src":"src/images/maskable_icon_x512.png","sizes":"512x512","type":"image/png","purpose":"any maskable"}],"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"4eb59912267e3a8aed0c46d666ac0dca"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[],"precachePages":["/about/","/blog/*"]},
    },{
      plugin: require('../gatsby-browser.ts'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
